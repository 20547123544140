
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  props: {
    type: {
      type: String as PropType<string>,
      default: 'primary',
    },
  },
})
