
import { VueComponent } from '@/data/types'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: '',
    },
    placeholder: {
      type: String as PropType<string>,
      default: 'Pesquisar...',
    },
  },
  emits: ['update:modelValue', 'cancel'],
  setup() {
    const searchInput = ref<VueComponent>()
    return { searchInput }
  },
  computed: {
    modelValueComputed: {
      get(): string {
        return this.modelValue
      },
      set(modelValue: string) {
        this.$emit('update:modelValue', modelValue)
      },
    },
  },
  methods: {
    handleCancel() {
      this.modelValueComputed = ''
      this.$emit('cancel')
    },
    focus() {
      this.searchInput?.$el.focus()
    },
  },
})
