
import { defineComponent, PropType, ref, nextTick } from 'vue'

import NavBar from '@/components/navbar/NavBar.vue'
import SearchBar from '@/components/SearchBar.vue'
import { NavigationOpts, Focusable } from '@/data/types'

export default defineComponent({
  components: { NavBar, SearchBar },
  props: {
    search: {
      type: String as PropType<string>,
      default: '',
    },
    navigation: {
      type: String as PropType<NavigationOpts>,
      default: 'none',
      validator: (value: NavigationOpts) =>
        ['menu', 'back', 'none'].includes(value),
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    searchPlaceholder: {
      type: String as PropType<string>,
      default: 'Pesquisar...',
    },
  },
  emits: ['update:search'],
  setup() {
    const searchBar = ref<Focusable>()
    return { searchBar }
  },
  data() {
    return {
      searching: false,
      showTitle: true,
      showToggle: true,
    }
  },
  computed: {
    searchComputed: {
      get(): string {
        return this.search
      },
      set(search: string) {
        this.$emit('update:search', search)
      },
    },
  },
  methods: {
    async openSearch() {
      this.searching = true
      await nextTick()
      this.searchBar?.focus()
    },
    closeSearch(): void {
      this.searching = false
    },
    openSideMenu(): void {
      this.$store.commit('expandSideMenu')
    },
    beforeAnimateSearchBar(): void {
      this.showTitle = false
      this.showToggle = false
    },
    afterAnimateSearchBar(): void {
      this.showTitle = true
      this.showToggle = true
    },
  },
})
